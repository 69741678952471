<script>
  export let color = 'white'; // 'white' | 'green' | 'yellow' | 'blue'
  export let imageSrc = '';
</script>

<div class="card card--{color}">
  <div class="card-inner">
    <slot><!-- optional fallback --></slot>
  </div>
</div>

<style>
  .card {
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
    word-break: keep-all;
  }

  .card--white {
    background-color: #ffffff;
  }

  .card--green {
    background-color: #eaf7db;
  }

  .card--yellow {
    background-color: #fffde7;
  }

  .card--blue {
    background-color: #e3f2fd;
  }

  .card-inner {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 1024px) {
    .card-inner {
      flex-direction: row;
      margin: 0 -15px;
    }
  }
</style>
