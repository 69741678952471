<script>
  import Card from '$c/Cards/Card.svelte';
  import Card2Cols from '$c/Cards/Card2Cols.svelte';
  // import { onMount } from 'svelte';
</script>

<main>
  <h1>Bookmaker single card</h1>
  <Card2Cols
    imageAlt="888starz"
    imageSrc="./888starz.bet_online_logo_470x246.png"
    countryCode="es"
    rating="9.19"
    year="2019" />
  <br />
  <Card2Cols
    color="green"
    year="2014"
    imageAlt="888starz"
    countryCode="ru"
    coin="Ethereum"
    rating="9.79"
    imageSrc="./888starz.bet_online_logo_470x246.png" />
</main>

<style>
  :global(body) {
    background: #eee;
    margin: 0;
  }
  main {
    margin: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    max-width: 1140px;
  }
</style>
