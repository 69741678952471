<script>
  import Card from './Card.svelte';
  import StarRating from '$c/StarRating';
  //import Checkmark from "$c/Icons/Checkmark.svelte";
  import Checkmark from 'carbon-icons-svelte/lib/Checkmark.svelte';
  import Calendar from 'carbon-icons-svelte/lib/Calendar.svelte';
  export let isFeatured = false;
  export let imageSrc = null;
  export let imageAlt = 'Image alt';
  export let year = '';
  export let countryCode = '';
  export let coin = '';
  export let rating = '';
</script>

<Card {...$$restProps}>
  <div class="card-image">
    <img width="470" height="246" src={imageSrc} alt={imageAlt} />
  </div>
  <div class="card-content">
    <!-- icon panel -->
    <div class="card-info">
      <div class="card-info-icons">
        {#if year}
          <span>
            <Calendar size={20} style="padding-right: 4px" />
            {year}
          </span>
        {/if}
        {#if countryCode}
          <span class="card-accent-text">
            <Checkmark size={20} />
            Aceptan jugadores de
            <span
              class="fl"
              style="background-image: url(../flags/4x3/{countryCode}.svg);" />
          </span>
        {/if}
        {#if coin}
          <span class="card-accent-text">
            <Checkmark size={20} />
            {coin}
          </span>
        {/if}
      </div>
      <div class="card-icon-stars">
        <StarRating
          {rating}
          config={{
            showText: true,
            fullColor: '#f08e00',
            emptyColor: '#595f68',
          }} />
      </div>
    </div>
    <div class="card-advantage-list" />
    <a class="card-button" />
  </div>
</Card>

<style>
  .fl {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333rem;
    height: 1rem;
    margin-left: 8px;
  }
  .fi:before {
    content: '\00a0';
  }
  .card-image {
    padding: 0 15px;
    box-sizing: border-box;
    flex: 0 1 auto;
    width: 33.33333333%;
  }
  .card-image img {
    display: inline-block;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    border-radius: 5px;
    border: 0;
    object-fit: contain;
  }
  .card-content {
    padding: 0 15px;
    flex: 1 0 auto;
  }
  .card-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .card-info-icons {
    display: flex;
  }
  .card-info-icons > * {
    padding-right: 1rem;
    display: flex;
    align-items: center;
  }
  .card-accent-text {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 1rem;
    color: green;
  }
  .card-icon-stars {
    padding-left: 1rem;
  }
</style>
